<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       preserveAspectRatio="none"
       viewBox="0 0 1134 364"
  >
    <g filter="url(#a)">
      <path fill="url(#b)"
            fill-opacity=".7"
            d="M1104 178.534v2.938L30 334V30l1074 148.534Z"
      />
      <path stroke="#000"
            d="m30.5 30.574 1073 148.396v2.068L30.5 333.424V30.574Z"
      />
    </g>
    <defs>
      <linearGradient id="b"
                      x1="1226.61"
                      x2="-35.062"
                      y1="182"
                      y2="182"
                      gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFABC8" />
        <stop offset=".448"
              stop-color="#9174FF"
        />
        <stop offset=".966"
              stop-color="#0A0A0A"
        />
      </linearGradient>
      <filter id="a"
              width="1134"
              height="364"
              x="0"
              y="0"
              color-interpolation-filters="sRGB"
              filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0"
                 result="BackgroundImageFix"
        />
        <feBlend in="SourceGraphic"
                 in2="BackgroundImageFix"
                 result="shape"
        />
        <feGaussianBlur result="effect1_foregroundBlur_2621_49100"
                        stdDeviation="15"
        />
      </filter>
    </defs>
  </svg>
</template>
